import { ServiceImpregnationService } from './../../../services/service-impregnation-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { ValidationController, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class ServiceImpregnationInlineEdit {
  @bindable private service: Models.Service;
  @bindable private impregnation: Models.ServiceImpregnation;
  @bindable private editFormVisible;

  private validationController: ValidationController;

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private toastService: ToastService,
    private serviceImpregnationService: ServiceImpregnationService,
    private t: I18N,
    validationControllerFactory: ValidationControllerFactory
  ) {
    this.validationController = validationControllerFactory.createForCurrentScope();
  }

  protected applyValidationRules(item: Models.ServiceImpregnation) {
    ValidationRules.ensure('ImpregnationLiters')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .min(0)
      .withMessage(this.t.tr('validation.mustBeGreaterOrEqualToValue', { value: 0 }))
      .ensure('ProductionNo')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .on(item);
  }

  private async validate(item: Models.ServiceImpregnation) {
    this.applyValidationRules(item);
    return (await this.validationController.validate({ object: this.impregnation })).valid;
  }

  protected async saveImpregnation() {
    if (this.service.IsInvoicingCompatible) {
      if (!(await this.validate(this.impregnation))) {
        return;
      }
    }

    this.serviceImpregnationService
      .put(this.impregnation, this.impregnation.Id)
      .then(() => {
        this.eventAggregator.publish('serviceImpregnationListReset', 0);
        this.toastService.showSuccess('service.ImpregnationSaved');
        this.editFormVisible = false;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private cancel() {
    this.editFormVisible = null;
    this.eventAggregator.publish('serviceImpregnationListCancel', 0);
  }
}
