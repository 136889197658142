import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, bindingMode } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { ServiceImpregnationService } from 'services/service-impregnation-service';
import { InvoiceBadge } from 'components/badge/invoice-badge';

@autoinject
export class ServiceImpregnationList {
  @bindable private service: Models.Service;
  @bindable private formVisible;
  @bindable private locked;
  @bindable private invoiceConsumptionImpregnationStatus: string;

  protected anyInvoiced = false;

  private impregnations: Array<Models.ServiceImpregnation>;
  @bindable({ defaultBindingMode: bindingMode.fromView }) private editFormVisible;
  @bindable({ defaultBindingMode: bindingMode.fromView }) private newFormVisible;
  private originals: any = {};

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private toastService: ToastService,
    private serviceImpregnationService: ServiceImpregnationService
  ) {}

  private attached() {
    this.getImpregnations();

    this.eventAggregator.subscribe('serviceImpregnationListReset', (value) => {
      this.getImpregnations();
    });

    this.eventAggregator.subscribe('serviceImpregnationListCancel', (value) => {
      this.impregnations = JSON.parse(JSON.stringify(this.originals.impregnations));
    });
  }

  private getImpregnations() {
    this.serviceImpregnationService
      .getAll('?$filter=ServiceId eq ' + this.service.Id)
      .then((res) => {
        this.impregnations = res;
        this.originals.impregnations = JSON.parse(JSON.stringify(res));
        const invoiceConsumptionImpregnationStatusList = this.impregnations.map((x) =>
          x.ServiceInvoiceId ? true : false
        );

        this.anyInvoiced = !!this.impregnations.find((x) => !!x.ServiceInvoiceId);

        this.invoiceConsumptionImpregnationStatus = InvoiceBadge.GetInvoiceType(
          invoiceConsumptionImpregnationStatusList
        );
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteImpregnation(impregnation) {
    this.serviceImpregnationService
      .delete(impregnation.Id)
      .then((res) => {
        this.eventAggregator.publish('serviceImpregnationListReset', 0);
        this.toastService.showSuccess('service.impregnationDeleted');
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
